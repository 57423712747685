// import _cloneDeep from 'lodash/cloneDeep';

import { DOMAIN } from '../config';

/**
 *
 * @param {String} entity required. name of entity.
 * @param {String?} dir - not required if specified, must be end with '/',
 * @returns formFields array.
 */
export const getJson = async (entity, dir = '') => import(`../json/formFields/${dir}${entity}Json.js`);

/**
 *
 * @param {Array<Object<String, String>>} queries [{key: "xx", value: 'yy' }, {...}]
 * @param {String} query :string of url query returned from recurring function
 * @param {Array<Object<String, String>>} headerQuery :
 * [{key: "buildings", value: ObjectId }, {key:'customer', },{key: building, }]
 */
export const setQueryForDataFetching = (originalQueries, query = '', headerQuery = []) => {
    // metto tutto insieme (queries, headerQuery)
    const queries = [...originalQueries, ...headerQuery];
    // check if object is passed as queries
    if (!Array.isArray(queries)) {
        return `?${queries.key}=${queries.value}`;
    }
    // Check if no more execution
    if (!queries.length) {
        return query;
    }
    // Don't modify arguments.
    let clonedQuery = query;
    const targetQuery = queries.shift();
    // store new queries array without index 0 to pass to recursion fn
    // check if cloneQuery. First time ?key=value
    if (!clonedQuery) {
        clonedQuery = `?${targetQuery.key}=${targetQuery.value}`;
        return setQueryForDataFetching(queries, clonedQuery);
    }
    // Here add new query to the string.
    clonedQuery += `&${targetQuery.key}=${targetQuery.value}`;
    return setQueryForDataFetching(queries, clonedQuery);
};
export function isObjectEmpty(value) {
    return Object.prototype.toString.call(value) === '[object Object]' && JSON.stringify(value) === '{}';
}

export function getCookie(name) {
    return document.cookie.split(';').some((c) => c.trim().startsWith(`${name}=`));
}
export function deleteCookie(name, path, domain) {
    if (getCookie(name)) {
        document.cookie = `${name}=${path ? `;path=${path}` : ''}${
            domain ? `;domain=${domain}` : ''
        };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
}

/**
 *
 * @param {Record<key, value>} data - converts all key value pair as url query string. \n setQueryForDataFetching converts array of object instead.
 */
export function createQuery(data) {
    const createdQuery = Object.entries(data).reduce((acc, [key, value]) => {
        if (!value) {
            return acc;
        }
        if (acc === '?') {
            acc += `${key}=${value}`;
            return acc;
        }
        acc += `&${key}=${value}`;
        return acc;
    }, '?');
    return createdQuery === '?' ? undefined : createdQuery;
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getCsvPrimitive = ({ array = [], obj }) => {
    const data = obj;
    if (!array.length) {
        return data;
    }
    const newData = data[array[0]];
    array.shift();
    return getCsvPrimitive({ array, obj: newData || '' });
};

/**
 *
 * @param {string} url
 * @param {string} keyword 'dashboard' by default it extracts the word after keyword
 * @returns
 */
export function extractWordFromUrl(url = window.location.href, keyword = DOMAIN) {
    const regex = new RegExp(`${keyword}\\/(\\w+)`);
    const match = regex.exec(url);
    const word = match ? match[1] : null;
    return word;
}

export const isLogPage = () => extractWordFromUrl() === 'devicelogs';
