import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { RHFTextField } from '../../../components/hook-form';
import { useLocale } from '../../../hooks/custom/useLocale';

export const ConfirmNonceCardContent = () => {
    const { t } = useLocale();
    return (
        <>
            {' '}
            <Stack spacing={0} mb={0} alignItems="center">
                <Typography variant="h4" component="h1">
                    {t('Reset Password')}
                </Typography>
                <Typography variant="body1" component="h3">
                    {t('Insert 6 digits code to reset password')}
                </Typography>
            </Stack>
            <Grid container item justifyContent="center" md={12}>
                <Grid item md={9}>
                    <RHFTextField type="number" sx={{ my: 4 }} id="nonce" name="nonce" label={t('6 digits code')} />
                </Grid>
            </Grid>
        </>
    );
};
