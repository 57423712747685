import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import { AppBar, Button, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { FormProvider } from '../../components/hook-form';
import Logo from '../../components/Logo';
import { useLocale } from '../../hooks/custom/useLocale';
import { LocaleMenuItem } from '../../layouts/dashboard/navbar/components/LocaleMenuItem';
import { _PATH_API_NEW } from '../../routes/api';
import { ConfirmNonceCardContent } from '../../sections/auth/reset-password/ConfirmNonceCardContent';
import { ResetPasswordCardContent } from '../../sections/auth/reset-password/ResetPasswordCardContent';
import axiosInstance from '../../utils/axios';
import { sleep } from '../../utils/helperFunctions';
import { EmailConfirmNonceCardContent } from '../../sections/auth/verify-email/EmailConfirmNonceCardContent';
import { EmailVerificationSuccessContent } from '../../sections/auth/verify-email/EmailVerificationSuccessContent';
// import LogoSquare from '../../components/LogoSquare';

const formFieldsData = [{ id: 'nonce', name: 'nonce', type: 'text', label: 'codice(6 cifre)' }];

const AuthVerificationTokenPage = () => {
    const [submitting, setSubmitting] = useState(false);
    const { t } = useLocale();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isVerified, setNonceIsCorrect] = useState(false);
    const { token } = useParams();

    useEffect(() => {
        verifyByToken();
    }, []);

    const verifyByToken = async () => {
        try {
            setSubmitting(true);
            const res = await axiosInstance.get(_PATH_API_NEW.auth.verifyEmailByNonceToken({ token }));
            if (res.data.success) {
                setNonceIsCorrect(true);
                enqueueSnackbar(t('Email verified successfully'), { variant: 'success' });
            } else {
                enqueueSnackbar(t('Invalid credentials'), { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar(t(error.message), { variant: 'error' });
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };
    return (
        <Container sx={{ height: '100vh', alignItems: 'center', display: 'flex' }}>
            <AppBar position="absolute" color="transparent" elevation={0} sx={{ mb: 5, px: 3 }}>
                <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
                    <Button href="/">← {t('Back to login')}</Button>
                    <LocaleMenuItem sx={{ px: 2 }} />
                </Stack>
            </AppBar>

            <Paper elevation={20} sx={{ maxWidth: 500, maxHeight: 700, margin: 'auto' }}>
                {/* <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}> */}
                <Grid container justifyContent="center" p={5}>
                    <Grid container justifyContent="center" mb={1}>
                        <Logo sx={{ width: 300, height: 75 }} />
                    </Grid>
                    {isVerified ? <EmailVerificationSuccessContent /> : <EmailConfirmNonceCardContent />}
                </Grid>
                {/* </FormProvider> */}
            </Paper>
        </Container>
    );
};

export default AuthVerificationTokenPage;
