import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { RHFTextField } from '../../../components/hook-form';
import { useLocale } from '../../../hooks/custom/useLocale';

export const EmailConfirmNonceCardContent = () => {
    const { t } = useLocale();
    return (
        <>
            {' '}
            <Stack spacing={4} mb={0} alignItems="center">
                <Typography variant="h4" component="h1">
                    {t('Email Verification')}
                </Typography>
                <Typography variant="body1" component="h3">
                    {t('We are verifying your email...')}
                </Typography>
                <CircularProgress />
            </Stack>
            <Grid container item justifyContent="center" md={12} />
        </>
    );
};
