/**
 * *************************************
 *  SHADAPPS CONFIDENTIAL
 *  __________________
 *
 *  Created by Vittorio Tauro
 *
 *  2022 (c) ShadApps Srl
 *  All Rights Reserved.
 *
 *  NOTICE:  All information contained herein is, and remains
 *  the property of ShadApps Srl and its suppliers,
 *  if any. The intellectual and technical concepts contained
 *  herein are proprietary to ShadApps Srl.
 *  and its suppliers and may be covered by Italian, European and Foreign Patents,
 *  patents in process, and are protected by trade secret or copyright law.
 *  Dissemination of this information or reproduction of this material
 *  is strictly forbidden unless prior written permission is obtained
 *  from ShadApps Srl.
 * *************************************
 * */

import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
// hooks
import useAuth from '../hooks/useAuth';
import { Logout } from '../pages/Logout';
import AuthVerificationTokenPage from '../pages/auth/AuthVerificationTokenPage';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

export const ROUTE_DASHBORD = {
    mobileCredentials: 'mobileCredentials',
};

export default function Router() {
    const { user } = useAuth();

    return useRoutes([
        {
            path: '/',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'logout',
                    element: <Logout />,
                },
                {
                    path: 'reset-password',
                    element: <SendResetPasswordEmailPage />,
                },
                {
                    path: 'forgot-password-email-sent',
                    element: <ResetPasswordEmailSentPage />,
                },
            ],
        },
        { path: 'password-forgot/token/:token', element: <ResetPasswordPage /> },
        { path: 'auth/verification/token/:token', element: <AuthVerificationTokenPage /> },

        {
            path: '/home',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                // { element: <Navigate to="/home" replace/>, index: true },
                { path: '', element: <Home /> },
            ],
        },

        {
            path: '/',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to="/home" replace />, index: true },
                // { path: 'home', element: <Home/> },
                { path: 'devices', element: user ? <Devices /> : <Page403 /> },
                { path: 'devicelogs', element: user ? <DeviceLogs /> : <Page403 /> },
                { path: 'locations', element: user ? <Tags /> : <Page403 /> },
                // Only B2B || Admin
                { path: 'employees', element: user ? <Employees /> : <Page403 /> },
                { path: 'crud', element: user ? <Crud /> : <Page403 /> },
                // only Admin
                { path: 'users', element: user?.role === 'admin' ? <Users /> : <Page403 /> },
                { path: 'verificationcodes', element: user ? <VerificationCodes /> : <Page403 /> },
                { path: 'usersettings', element: user ? <UserSettings /> : <Page403 /> },

                /** Only userb2b */
                {
                    path: ROUTE_DASHBORD.mobileCredentials,
                    element: ['userb2b', 'admin'].includes(user?.role) ? <CredentialPage /> : <Page403 />,
                },
            ],
        },
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> },
                { path: '500', element: <Page500 /> },
                { path: '404', element: <Page404 /> },
                { path: '403', element: <Page403 /> },
            ],
        },
        { path: 'reset-device/:token/:deviceId', element: <ResetConfirmPage /> },
        // { path: 'reset-device/:token', element: <PasswordResetPage /> },

        { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
const SendResetPasswordEmailPage = Loadable(lazy(() => import('../pages/auth/SendResetPasswordEmailPage')));
const ResetPasswordEmailSentPage = Loadable(lazy(() => import('../pages/ResetPasswordEmailSentPage')));

// PAGES
const Home = Loadable(lazy(() => import('../pages/custom/Home')));
const ResetConfirmPage = Loadable(lazy(() => import('../pages/custom/ResetConfirmPage')));
const DeviceLogs = Loadable(lazy(() => import('../pages/crud/DeviceLogsPage')));
const Devices = Loadable(lazy(() => import('../pages/crud/DevicesPage')));
const Employees = Loadable(lazy(() => import('../pages/crud/EmployeesPage')));
const Tags = Loadable(lazy(() => import('../pages/crud/LocationsPage')));
const Users = Loadable(lazy(() => import('../pages/crud/UsersPage')));
const UserSettings = Loadable(lazy(() => import('../pages/crud/UserSettingsPage')));
const VerificationCodes = Loadable(lazy(() => import('../pages/crud/VerificationCodesPage')));
const Crud = Loadable(lazy(() => import('../pages/crud/CrudPage')));

const CredentialPage = Loadable(lazy(() => import('../pages/b2b/CredentialPage')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// MAIN
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
