import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { RHFTextField } from '../../../components/hook-form';
import { useLocale } from '../../../hooks/custom/useLocale';

export const ResetPasswordCardContent = () => {
    const { t } = useLocale();
    return (
        <>
            {' '}
            <Stack spacing={0} mb={0} alignItems="center">
                <Typography variant="h4" component="h1">
                    {t('Reset Password')}
                </Typography>
                <Typography variant="body1" component="h3">
                    {t('Insert new password')}
                </Typography>
            </Stack>
            <Grid container item justifyContent="center" md={12}>
                <Grid item md={9}>
                    <RHFTextField type="password" sx={{ my: 1 }} id="password" name="password" label={t('Password')} />
                    <RHFTextField
                        type="password"
                        sx={{ my: 1 }}
                        id="password2"
                        name="password2"
                        label={t('Password confirm')}
                    />
                </Grid>
            </Grid>
        </>
    );
};
