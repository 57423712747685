import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { RHFTextField } from '../../../components/hook-form';
import { useLocale } from '../../../hooks/custom/useLocale';
import { SuccessIcon } from '../../../theme/overrides/CustomIcons';

export const EmailVerificationSuccessContent = () => {
    const { t } = useLocale();
    return (
        <>
            {' '}
            <Stack spacing={2} mb={0} alignItems="center">
                <Typography variant="h4" component="h1">
                    {t('Successfully verified email')}
                </Typography>
                <SuccessIcon sx={{ fontSize: 64 }} color="success" />
                <Typography variant="body1" component="h3" textAlign="center">
                    {t('You can now login to your account from the App Expoalert')}
                </Typography>
            </Stack>
        </>
    );
};
